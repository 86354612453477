//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.card-login {
	margin-bottom: 0;
	width: 100%;
	border-radius: 26px;

	@include media-breakpoint-up(sm) {
		margin-left: 30px;
		margin-right: 30px;
	}
	@include media-breakpoint-up(xl) {
		width: 80%;
	}
}

.bg-login {
	// background-color: #def7ff;
	background-color: #11445a;
}

.img-content {
	background-image: url('../../assets/img/login-car.jpeg');
	background-size: cover;
	background-position: center center;
	width: 100%;
	height: 100%;

	border-top-left-radius: 26px;
	border-bottom-left-radius: 26px;
}

.login-content {
	padding: 25px;
	@include media-breakpoint-up(lg) {
		padding-left: 50px;
		padding-right: 50px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
	@include media-breakpoint-up(xl) {
		padding-left: 100px;
		padding-right: 100px;
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
